<template lang="pug">
  .generation_chart
    Chart(
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :id="id"
    )
</template>

<script>
import Chart from './ChartItem.vue'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

dayjs.locale('ja')
export default {
  components: {
    Chart,
  },
  props: {
    id: String,
    target: Object,
  },
  data() {
    return {
      chartType: 'doughnut',
      chartOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              boxWidth: 15,
            },
          },
          tooltip: {
            mode: 'index',
            callbacks: {
              title: function () {
                return ''
              },
              label: function (tooltipItems) {
                let dataArray = tooltipItems.dataset.data
                let total = dataArray.reduce(function (sum, element) {
                  return sum + element
                }, 0)
                return (
                  ' ' +
                  tooltipItems.label +
                  '：' +
                  Math.round((tooltipItems.raw / total) * 1000) / 10 +
                  '%\n(' +
                  tooltipItems.raw +
                  '名)'
                )
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartData() {
      let generationList = {}
      Object.keys(this.target).forEach((userId) => {
        const account = this.target[userId]
        if (
          typeof account.birthday != 'undefined' &&
          account.birthday != null
        ) {
          let generation = this.getGeneration(account.birthday.seconds)
          if (generation) {
            if (!(generation in generationList)) {
              generationList[generation] = 0
            }
            generationList[generation]++
          }
        }
      })
      let labels = []
      let datas = []
      let counts = []
      Object.keys(generationList).forEach((generation) => {
        labels.push(generation + '代')
        counts.push(generationList[generation])
        datas.push(generationList[generation])
      })
      return {
        labels: labels,
        datasets: [
          {
            indexAxis: 'y',
            pointRadius: 2,
            data: datas,
            backgroundColor: [
              '#F6BB9952',
              '#E4808052',
              '#076F9452',
              '#25A9B252',
              '#9EE2C752',
            ],
            borderColor: 'rgb(236 236 236)',
            lineTension: 0,
            pointBackgroundColor: 'rgb(210 210 210)',
          },
        ],
      }
    },
  },
  methods: {
    // 世代変換メソッド
    getGeneration(seconds) {
      try {
        const dateOfBirth = dayjs(new Date(seconds * 1000))
        const today = dayjs(new Date())
        let baseAge = today.year() - dateOfBirth.year()

        const birthday = dayjs(
          new Date(
            today.year() +
              '/' +
              (dateOfBirth.month() + 1) +
              '/' +
              dateOfBirth.date(),
          ),
        )
        let age = null
        if (today.isBefore(birthday)) {
          age = baseAge - 1
        } else {
          age = baseAge
        }
        return parseInt(age / 10) * 10
      } catch (e) {
        return null
      }
    },
  },
}
</script>
<style>
.generation_chart canvas {
  max-width: 350px;
}
</style>
