<template lang="pug">
  .lang_chart
    Chart(
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :id="id"
    )
</template>

<script>
import Chart from './ChartItem.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Chart,
  },
  props: {
    id: String,
    target: Object,
  },
  data() {
    return {
      chartType: 'bar',
      chartOptions: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          x: {
            ticks: {
              callback: function (value) {
                return value + '名'
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            enabled: false,
            callbacks: {
              title: function () {
                return ''
              },
              label: function (tooltipItems) {
                var label =
                  tooltipItems.label + '（' + tooltipItems.raw + '名）'
                return label
              },
            },
            displayColors: false,
          },
        },
        elements: {
          line: {
            borderWidth: 1,
          },
        },
      },
    }
  },
  computed: {
    ...mapState('masterModule', ['master']),
    chartData() {
      // マスター登録されている言語リスト取得
      let langs = this.master.langs
      Object.keys(langs).forEach(function (id) {
        // 初期化
        delete langs[id].count
      })

      // ターゲット分ループ処理
      Object.keys(this.target).forEach((userId) => {
        const account = this.target[userId]
        // 言語が登録されている場合
        if (typeof account.langs != 'undefined' && account.langs != null) {
          account.langs.forEach((langId) => {
            if (typeof langs[langId].count == 'undefined') {
              langs[langId].count = 0
            }
            langs[langId].count++
          })
        }
      })

      let labels = []
      let datas = []
      let langsList = []
      Object.keys(langs).forEach((langId) => {
        if (typeof langs[langId].count != 'undefined' && langs[langId].count) {
          langsList.push(langs[langId])
        }
      })

      let sortedLang = langsList.sort((a, b) => b.count - a.count)
      sortedLang.forEach((val) => {
        if (val.count > 0) {
          labels.push(val.name + ' (' + val.count + '名)')
          datas.push(val.count)
        }
      })
      return {
        labels: labels,
        datasets: [
          {
            pointRadius: 2,
            data: datas,
            backgroundColor: '#25A9B252',
            borderColor: '#25A9B252',
            lineTension: 0,
            pointBackgroundColor: '#25A9B252',
          },
        ],
      }
    },
  },
}
</script>
<style>
.lang_chart canvas {
  max-width: 100%;
  height: 400px;
}
</style>
