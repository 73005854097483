<template lang="pug">
  .statistics_item
    .chart_inner.gender_wrap
      h2 男女比
      GenderChart(:id="type + '_gender'" :target="departmentsUsers")
    .chart_inner.generatin_wrap
      h2 年代比
      GenerationChart(:id="type + '_generation'" :target="departmentsUsers")
      p.attention ※誕生日を入力しているユーザのみの統計
    .chart_inner.langs_wrap
      h2 言語比
        small （登録数順）
      LangChart(:id="type + '_lang'" :target="departmentsUsers")
      p.attention ※言語を入力しているユーザのみの統計
</template>
<script>
import LangChart from '@/components/chart/LangChart.vue'
import GenderChart from '@/components/chart/GenderChart.vue'
import GenerationChart from '@/components/chart/GenerationChart.vue'
export default {
  name: 'StatisticsItem',
  components: {
    LangChart,
    GenderChart,
    GenerationChart,
  },
  props: {
    type: String,
    departmentsUsers: Object,
  },
}
</script>
<style lang="scss" scoped>
.statistics_item {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 1020px;
  margin: 0 auto;
  .chart_inner {
    margin: 0 auto 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    font-size: 18px;
    margin: 10px auto 20px;
    small {
      font-size: 14px;
    }
  }
  .attention {
    display: block;
    text-align: center;
    color: #666;
    margin: 15px 0 0;
    font-size: 13px;
  }
}
</style>
