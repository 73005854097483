<template lang="pug">
  div.statistics
    MainHeadItem(iconName="statistics" title="会社統計データ")
      IconStatistics
    //- ul.department_tabs
    //-   li.all(@click="tabSelect('all')" :class="{'active': isActive === 'all'}") 全体
    //-   li.west(@click="tabSelect('west')" :class="{'active': isActive === 'west'}") West
    //-   li.east(@click="tabSelect('east')" :class="{'active': isActive === 'east'}") East

    ul.chart_wrap
      li.all(v-if="isActive === 'all'")
        StatisticsItem(type="all" :departmentsUsers="departmentsUsers.all")
      //- li.west(v-else-if="isActive === 'west'")
      //-   StatisticsItem(type="west" :departmentsUsers="departmentsUsers.west")
      //- li.east(v-else-if="isActive === 'east'")
      //-   StatisticsItem(type="east" :departmentsUsers="departmentsUsers.east")

</template>
<script>
import { mapState } from 'vuex'
import MainHeadItem from '@/components/MainHeadItem.vue'
import IconBase from '@/components/IconBase.vue'
import IconStatistics from '@/components/icons/IconStatistics.vue'
import StatisticsItem from '@/components/StatisticsItem'
export default {
  name: 'StatisticsView',
  metaInfo: {
    title: '会社統計画面',
  },
  components: {
    MainHeadItem,
    StatisticsItem,
    IconBase,
    IconStatistics,
  },
  data() {
    return {
      isActive: 'all',
      departmentsUsers: {
        all: {},
        west: {},
        east: {},
      },
    }
  },
  computed: {
    ...mapState('usersModule', ['users']),
  },
  created() {
    this.groupDepartment()
  },
  methods: {
    groupDepartment() {
      this.departmentsUsers.all = this.users
      Object.keys(this.users).forEach((userId) => {
        const account = this.users[userId]
        // West(5) / East(6) マジックナンバーT.B.D
        if (account.departmentId == 5) {
          this.departmentsUsers.west[userId] = account
        } else if (account.departmentId == 6) {
          this.departmentsUsers.east[userId] = account
        }
      })
    },
    tabSelect: function (type) {
      this.isActive = type
    },
  },
}
</script>
<style lang="scss" scoped>
.statistics {
  .department_tabs {
    position: sticky;
    top: 0;
    z-index: 15;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    li {
      width: calc(100% / 3);
      padding: 15px 0;
      text-align: center;
      cursor: pointer;
      transition: all 0.5s ease;
      text-decoration: none;
      border-bottom: 3px solid #eaeaea;
      &.active {
        border-color: #88cabe;
        color: #88cabe;
        font-weight: bold;
      }
    }
  }
  .chart_wrap {
    padding: 30px 15px 0;
  }
}
</style>
