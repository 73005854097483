<template lang="pug">
  .chartType
    canvas(:id="id")
</template>

<script>
import * as ChartJs from 'chart.js'

ChartJs.Chart.register.apply(
  null,
  Object.values(ChartJs).filter((chartClass) => chartClass.id),
)
export default {
  data() {
    return {
      chart: null,
    }
  },
  props: {
    chartType: String,
    chartData: Object,
    chartOptions: Object,
    id: String,
  },
  methods: {
    chartConstructor(chartType, chartData, chartOptions) {
      const chartElement = document.querySelector(`#${this.id}`)
      this.chart = new ChartJs.Chart(chartElement, {
        type: chartType,
        data: chartData,
        options: chartOptions,
      })
    },
  },

  mounted() {
    let { chartType, chartData, chartOptions } = this
    this.chartConstructor(chartType, chartData, chartOptions)
  },
  watch: {
    chartData() {
      this.chart.data.datasets[0].data = this.chartData.datasets[0].data
      this.chart.data.labels = this.chartData.labels
      this.chart.update()
    },
  },
}
</script>
