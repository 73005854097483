<template lang="pug">
  .gender_chart
    Chart(
      :chartData="chartData"
      :chartOptions="chartOptions"
      :chartType="chartType"
      :id="id"
    )
</template>

<script>
import Chart from './ChartItem.vue'
export default {
  components: {
    Chart,
  },
  props: {
    id: String,
    target: Object,
  },
  data() {
    return {
      chartType: 'doughnut',
      chartOptions: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              boxWidth: 20,
            },
          },
          tooltip: {
            mode: 'index',
            callbacks: {
              title: function () {
                return ''
              },
              label: function (tooltipItems) {
                let dataArray = tooltipItems.dataset.data
                let total = dataArray.reduce(function (sum, element) {
                  return sum + element
                }, 0)
                return (
                  ' ' +
                  tooltipItems.label +
                  '：' +
                  Math.round((tooltipItems.raw / total) * 1000) / 10 +
                  '%\n(' +
                  tooltipItems.raw +
                  '名)'
                )
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartData() {
      let genderCount = {
        male: 0,
        female: 0,
      }
      Object.keys(this.target).forEach((userId) => {
        genderCount[this.target[userId].gender]++
      })

      return {
        labels: ['男性', '女性'],
        datasets: [
          {
            indexAxis: 'y',
            pointRadius: 2,
            data: [genderCount.male, genderCount.female],
            backgroundColor: ['#076F9452', '#E4808052'],
            borderColor: 'rgb(236 236 236)',
            lineTension: 0,
            pointBackgroundColor: 'rgb(210 210 210)',
          },
        ],
      }
    },
  },
}
</script>
<style>
.gender_chart canvas {
  max-width: 350px;
}
</style>
